<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    src?: string
    srcFullscreen?: string
    width?: string
    alt?: string
    rounded?: boolean
    radius?: string
  }>(),
  {
    width: '100%',
    rounded: true,
    radius: '1em',
  },
)

const isFullscreen = ref(false)

function openFullscreen() {
  isFullscreen.value = true
}

function closeFullscreen() {
  isFullscreen.value = false
}

const imageSrc = computed(() => {
  return props.srcFullscreen ? props.srcFullscreen : props.src
})

// Close fullscreen on Escape key press
const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    closeFullscreen()
  }
}

// Attach and remove event listener
onMounted(() => {
  window.addEventListener('keydown', handleKeydown)
})
</script>

<template>
  <div class="image-container">
    <!-- Clickable Thumbnail -->
    <img
      :src="props.src"
      :alt="props.alt"
      class="thumbnail"
      :class="{ 'rounded-img': props.rounded }"
      @click="openFullscreen"
    />

    <!-- Fullscreen Overlay -->
    <div v-if="isFullscreen" class="fullscreen" @click="closeFullscreen">
      <VBtn icon="mdi-close" class="close-button" />
      <img :src="imageSrc" class="fullscreen-img" :class="{ 'rounded-img': props.rounded }" />
    </div>
  </div>
</template>

<style lang="css" scoped>
/* Thumbnail Styling */
.thumbnail {
  width: v-bind(width);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.rounded-img {
  border-radius: v-bind(radius);
}

.thumbnail:hover {
  transform: scale(1.05);
}

/* Fullscreen Overlay */
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

/* Fullscreen Image */
.fullscreen-img {
  max-width: 90%;
  max-height: 90%;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
